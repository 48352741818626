.container {
  padding: 16px 20px;
}

.title {
  color: var(--color-label-neutral-standard-primary, #222);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.iconText {
  color: var(--color-label-neutral-standard-primary, #222);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
