.container {
  text-align: center;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  flex-direction: column;
  gap: 8px;
}

.error404 {
  font-weight: bold;
  font-size: 30px;
  margin: 0;
}

.paragraph {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #2222227b;
}

.button {
  margin-top: 12px;
  width: 250px;
}
