@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* reset.css */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f4f4f4;
  line-height: 1.6;
}

a {
  color: #007bff;
  text-decoration: none;
}

*,
body {
  font-family: 'Poppins', sans-serif;
}
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*
 * Additional Ionic Colors
 * ----------------------------------------------------------------------------
 * In order to add colors to be used within Ionic components,
 * the color should be added as a class with the convention `.ion-color-{COLOR}`
 * where `{COLOR}` is the color to be used on the Ionic component.
 * For more information on adding new colors, please see
 * https://ionicframework.com/docs/theming/colors#adding-colors
 *
 * To generate the code for a new color, check out our new color creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator
 */

:root {
  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105, 187, 123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;

  --ion-color-twitter: #1da1f4;
  --ion-color-twitter-rgb: 29, 161, 244;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255, 255, 255;
  --ion-color-twitter-shade: #1a8ed7;
  --ion-color-twitter-tint: #34aaf5;

  --ion-color-instagram: #5956d8;
  --ion-color-instagram-rgb: 89, 86, 216;
  --ion-color-instagram-contrast: #ffffff;
  --ion-color-instagram-contrast-rgb: 255, 255, 255;
  --ion-color-instagram-shade: #4e4cbe;
  --ion-color-instagram-tint: #6a67dc;

  --ion-color-vimeo: #23b6ea;
  --ion-color-vimeo-rgb: 35, 182, 234;
  --ion-color-vimeo-contrast: #ffffff;
  --ion-color-vimeo-contrast-rgb: 255, 255, 255;
  --ion-color-vimeo-shade: #1fa0ce;
  --ion-color-vimeo-tint: #39bdec;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-instagram {
  --ion-color-base: var(--ion-color-instagram);
  --ion-color-base-rgb: var(--ion-color-instagram-rgb);
  --ion-color-contrast: var(--ion-color-instagram-contrast);
  --ion-color-contrast-rgb: var(--ion-color-instagram-contrast-rgb);
  --ion-color-shade: var(--ion-color-instagram-shade);
  --ion-color-tint: var(--ion-color-instagram-tint);
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-github {
  --ion-color-base: #211f1f;
  --ion-color-base-rgb: 33, 31, 31;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1d1b1b;
  --ion-color-tint: #373535;
}

.ion-color-instagram {
  --ion-color-base: #9537bc;
  --ion-color-base-rgb: 149, 55, 188;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #8330a5;
  --ion-color-tint: #a04bc3;
}

/*
 * Shared Variables
 * ----------------------------------------------------------------------------
 * To customize the look and feel of this app, you can override
 * the CSS variables found in Ionic's source files.
 * To view all of the possible Ionic variables, see:
 * https://ionicframework.com/docs/theming/css-variables#ionic-variables
 */

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-tab-bar-color: #000;
  --ion-tab-bar-color-selected: #fff;
  --ion-tab-bar-background: #eb445a;
}

/*
 * App iOS Variables
 * ----------------------------------------------------------------------------
 * iOS only CSS variables can go here
 */

.ios {
}

/*
 * App Material Design Variables
 * ----------------------------------------------------------------------------
 * Material Design only CSS variables can go here
 */

.md {
}

/*
 * App Theme
 * ----------------------------------------------------------------------------
 * Ionic apps can have different themes applied, which can
 * then be further customized. These variables come last
 * so that the above variables are used by default.
 */

/*
 * Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.ios {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #000000;
}

/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.md {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;
}

.button_button__1yjAB {
  display: flex;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  padding: 12px;
  padding: var(--space-padding-sm, 12px);
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  grid-gap: var(--space-gap-sm, 12px);
  gap: var(--space-gap-sm, 12px);
  align-self: stretch;
  border-radius: 8px;
  border-radius: var(--space-radius-sm, 8px);
  background: #00a7f0;
  background: var(--color-fill-brand-primary-default, #00a7f0);
  transition: background-color 0.3s;
  color: #fafafa;
  color: var(--color-label-neutral-bgblackish-primary, #fafafa);
  font-family: Poppins;
  font-family: var(--type-family-principal, Poppins);
  font-size: 16px;
  font-size: var(--type-size-sm, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button_button__1yjAB:hover {
  background-color: #1a7de6;
}
.button_button__1yjAB:active {
  background-color: #4b98ea;
}

.button_button__1yjAB:focus {
  background-color: #3791f0;
}

.button_button__1yjAB:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Typography.css */
.typography {
  margin: 0;
  font-family: Arial, sans-serif;
}

.typography.h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.typography.h2 {
  font-size: 2rem;
  font-weight: bold;
}

.typography.h3 {
  font-size: 1.75rem;
  font-weight: bold;
}

.typography.h4 {
  font-size: 1.5rem;
  font-weight: bold;
}

.typography.h5 {
  font-size: 1.25rem;
  font-weight: bold;
}

.typography.h6 {
  font-size: 1rem;
  font-weight: bold;
}

.typography.p {
  font-size: 1rem;
}

.typography.span {
  font-size: 1rem;
}

.typography.small {
  font-size: 0.875rem;
}

.input_label__3Itj8 {
  overflow: hidden;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 4px;
}

.input_input__1P--v {
  width: 100%;
  display: flex;
  padding: 12px 4px
    12px 12px;
  padding: var(--space-padding-sm, 12px) var(--space-padding-xxs, 4px)
    var(--space-padding-sm, 12px) var(--space-padding-sm, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0;
  align-self: stretch;
  border-radius: 8px;
  border-radius: var(--space-radius-sm, 8px);
  border: 1px solid
    rgba(34, 34, 34, 0.08);
  border: var(--space-stroke-sm, 1px) solid
    var(--color-stroke-neutral-standard-secondary, rgba(34, 34, 34, 0.08));
  background: #fff;
  background: var(--color-background-neutral-primary, #fff);
  display: flex;
  height: 48px;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1 0;
}

.input_input__1P--v::-webkit-input-placeholder {
  overflow: hidden;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input_input__1P--v::-ms-input-placeholder {
  overflow: hidden;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input_input__1P--v::placeholder {
  overflow: hidden;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input_input__1P--v:focus {
  outline: none;
  border: 1px solid #00a7f0 !important;
}

.input_inputWarning__1emK9 {
  border: 1px solid #d6780c;
}

.input_warning__2Ocsm {
  margin: 0;
  margin-top: 4px;
  overflow: hidden;
  color: #d6780c;
  text-overflow: ellipsis;
  font-size: 10px;
  font-size: var(--type-size-xxxs, 10px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.checkin_eventBoxTitle__2GHkr {
  display: flex;
  width: 100%;
  padding: 14px 19px !important;
  justify-content: start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.checkin_eventTitle__2Cj68 {
  overflow: hidden;
  color: #111;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.scanner_scannerBox__3s-qO {
  position: relative;
  width: 100%;
  min-height: 260px;
  max-height: 340px;
  padding: 0 !important;
}

.scanner_scannerTarget__2_wTd {
  position: absolute;
  width: 140px;
  height: 140px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 9999;
}

.scanner_qrReader__1C9Rb {
  width: 100%;
  min-height: 260px;
  max-height: 340px;
}

.skeleton_loading__2huO3 {
  display: flex;
  background: linear-gradient(to right, #eee 25%, #ddd 50%, #eee 75%);
  background-size: 200% 100%;
  -webkit-animation: skeleton_loading__2huO3 1.5s infinite ease-in-out;
          animation: skeleton_loading__2huO3 1.5s infinite ease-in-out;
}

@-webkit-keyframes skeleton_loading__2huO3 {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

@keyframes skeleton_loading__2huO3 {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

/* texto de ajuda */
.message_helpText___1nGV {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 336px;
  margin: auto;
  margin-top: 24px;
}

/* CheckinStatus */
.message_container__3jjYB {
  margin-top: 10px;
  padding: 14px;
  border: 1px solid #eee;
  border-radius: 8px;
  width: 90% !important;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.message_header__3u4so {
  align-items: center;
  justify-content: space-between;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.message_header__3u4so h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.message_header__3u4so h2 {
  color: #888;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 100%;
  margin: 0;
}

.message_body__2e2TN {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 2px;
  gap: 2px;
  align-self: stretch;
}

.message_paragraph__jL2rf {
  overflow: hidden;
  color: #222;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.message_smallParagraph__3sJJ6 {
  color: #222;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.message_documentParagraph__KfJPx,
.message_ticketNumberParagraph__3DD0i {
  color: #888;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* ------------MenuIconButton */
/* box do menu */
.menu_menuBox__1erS7 {
  position: absolute;
  left: 33px;
  top: 53%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 26px;
  z-index: 999;
}

/* Botão de abrir menu */
.menu_menuIcon__9tci3 {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

/* ------------MenuBody */
.menu_menuMainBox__2l1LY {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  visibility: hidden;
  transition: visibility 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  height: 100vh;
}

.menu_menuMainBox__2l1LY.menu_open__xVsBA {
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.menu_menuIconBackBox__1YNpV {
  height: 52px;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  background-color: #fafafa;
  cursor: pointer;
}
.menu_menuIconBack__3SC-l {
  cursor: pointer;
}

.menu_menuBodyBox__3cPzc {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 85%;
  background-color: #ffffff;
}

.menu_menuBodyTitle__et6_X {
  margin: 0;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  font-size: 16px;
  font-size: var(--type-size-sm, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 16px;
}

.menu_menuBodyButton__r6Wws {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 4px;
  padding: var(--space-padding-md, 16px) var(--space-padding-xxs, 4px);
  grid-gap: 12px;
  gap: 12px;
  grid-gap: var(--space-padding-sm, 12px);
  gap: var(--space-padding-sm, 12px);
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  transition: border-color 0.3s ease;
}

.menu_menuBodyButton__r6Wws:hover {
  display: flex;
  align-items: center;
  padding: 16px 4px;
  padding: var(--space-padding-md, 16px) var(--space-padding-xxs, 4px);
  grid-gap: 12px;
  gap: 12px;
  grid-gap: var(--space-padding-sm, 12px);
  gap: var(--space-padding-sm, 12px);
  border-bottom: 1px solid #00a7f0;
  cursor: pointer;
}

.menu_menuBodyLabels__1VrOy {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  flex-direction: column;
}

.menu_menuBodyButtonLabel__1XoPl {
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  font-size: 14px;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.menu_menuBodyButtonSubLabel__1Cyih {
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.menu_menuBodyArrowForward__1bETE {
  margin-left: auto;
  margin-right: 10px;
}

.menu_menuLogoutButton__2zaFO {
  margin-top: auto;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  grid-gap: 12px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  padding: 16px 4px;
  transition: border-color 0.3s ease;
}
.menu_menuLogoutButton__2zaFO:hover {
  border-bottom: 1px solid #00a7f0;
  grid-gap: 12px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  padding: 16px 4px;
  cursor: pointer;
}
.menu_menuLogoutText__3HRqd {
  margin: 0;
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  /* Label medium */
  font-family: Poppins;
  font-family: var(--type-family-principal, Poppins);
  font-size: 14px;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.menu_menuVersionBox__r4RxW {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.menu_menuVersionBoxText__2RyQZ {
  margin: 0;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navbar_navbarBox__34uKb {
  position: relative;
  height: 52px;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08) !important;
  background-color: #fafafa;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar_navbarLogo__2fndo {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 126px;
  height: 26px;
  z-index: 2;
}

.alert_alertMainBox__1Dlrh {
  display: flex;
  flex-direction: column;
  height: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.alert_alertMainBox__1Dlrh.alert_alertMainOpen__5UB_m {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  height: auto;
  height: initial;
}

.alert_alertBox__2OETB {
  height: 45px;
  padding: 12px 20px;
  color: #ffffff;
  text-align: start;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0; /* Use opacity instead of visibility */
  transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.alert_alertBox__2OETB.alert_alertOpen__ksUqZ {
  opacity: 1; /* Use opacity instead of visibility */
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.alert_alertMessage__Ds9aP {
  color: #fafafa;
  color: var(--color-label-neutral-bgblackish-primary, #fafafa);
  font-size: 14px;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  line-height: 1.02;
  width: 90%;
}

.alert_subMessage__1lRrY {
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.alert_xIcon__qPTUz {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
}

.form_container__1SJ0H {
  padding: 16px 20px;
}

.form_title__36FQQ {
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.form_iconText__8a8EC {
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.tag_tag__1Z0g- {
  display: inline-flex;
  align-self: flex-start; /* This prevents it from filling the cross-axis in a flex parent */
  padding: 4px 12px;
  padding: var(--space-padding-xxs, 4px) var(--space-padding-sm, 12px);
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  grid-gap: var(--space-gap-xs, 8px);
  gap: var(--space-gap-xs, 8px);
  border-radius: 4px;
  border-radius: var(--space-radius-xs, 4px);
  background: #53a462;
  background: var(--color-fill-feedback-success-primary-default, #53a462);
  color: #fafafa;
  color: var(--color-label-neutral-bgblackish-primary, #fafafa);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.updateAttendee_menuBox__2Ip3U {
  position: absolute;
  left: 40px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 26px;
  z-index: 999;
}

/* Botão de abrir menu */
.updateAttendee_menuIcon__3HgEE {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.searchAttendee_container__1SQmv {
  padding: 16px 20px;
}

.searchAttendee_loadingBox__2t8xP {
  margin-top: 28px;
  grid-gap: 6px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* texto de ajuda */
.attendeesList_helpText__33kbb {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 336px;
  margin: auto;
  margin-top: 24px;
}

/* AttendeeCard */
.attendeesList_container__A4KMd {
  padding: 14px 0px;
  margin: 0px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  grid-gap: 20px !important;
  gap: 20px !important;
  cursor: pointer;
  transition: border-color 0.3s ease;
  box-shadow: none;
  justify-content: space-between;
}

.attendeesList_container__A4KMd:hover {
  border-color: #eeeeee;
  background-color: #fbfafa;
}

.attendeesList_container__A4KMd:active {
  border-color: #c9c8f0;
  background-color: #efeff2;
}

.attendeesList_header__3x1RK {
  align-items: center;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.attendeesList_header__3x1RK h1 {
  color: blue;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.attendeesList_leftBodyCard__2ryZu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 2px;
  gap: 2px;
  align-self: stretch;
  width: 80%;
}

.attendeesList_rightBodyCard__Bmsp6 {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.attendeesList_doCheckin__3w-mq {
  display: flex;
  height: 28px;
  padding: 4px;
  padding: var(--space-padding-xxs, 4px);
  justify-content: center;
  align-items: center;
  width: 110px;
  grid-gap: 4px;
  gap: 4px;
  grid-gap: var(--space-gap-xxs, 4px);
  gap: var(--space-gap-xxs, 4px);
  border-radius: 4px;
  border-radius: var(--space-radius-xs, 4px);
  border: 1px solid
    #00b2ff;
  border: var(--space-stroke-sm, 1px) solid
    var(--color-stroke-brand-primary, #00b2ff);
  color: #00b2ff;
  color: var(--color-label-brand-default, #00b2ff);
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: background-color 0.3s, color 0.3s;
}

.attendeesList_doCheckin__3w-mq:hover {
  background-color: #00b2ff;
  background-color: var(--color-stroke-brand-primary, #00b2ff);
  color: #fff;
}

.attendeesList_paragraph__2hLJV {
  overflow: hidden;
  color: #222;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.attendeesList_smallParagraph__2r2o9 {
  color: #222;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.attendeesList_documentParagraph__4XUit,
.attendeesList_ticketNumberParagraph__1xd2J {
  color: #888;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.attendeesList_boxNotFound__2SKUZ {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  padding: 16px;
  align-items: center;
  width: 100%;
}

.attendeesList_iconNotFound__lCJgD {
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: center;
  font-size: 18px;
  font-size: var(--type-size-md, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.attendeesList_textNotFound__2Ezyt {
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: center;
  font-size: 18px;
  font-size: var(--type-size-md, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.searchBar_title__CpZiI {
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: start;
  font-size: 16px;
  font-size: var(--type-size-sm, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 24px;
}

.searchBar_searchBar__2lnnk {
  --background: #f7f9fa;
  --background-hover: #9ce0be;
  --background-activated: #2070fc;
  --background-focused: hsl(218, 97%, 56%);
  --color: #222;
  --box-shadow: 0;
  --border-radius: 480px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  border-radius: 480px;
  border: 1px solid rgba(34, 34, 34, 0.08);
  padding: 0 !important;
  margin-bottom: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchBar_searchButton__2hcDg {
  display: flex;
  height: 48px;
  padding: 12px;
  padding: var(--space-padding-sm, 12px);
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  grid-gap: var(--space-gap-sm, 12px);
  gap: var(--space-gap-sm, 12px);
  align-self: stretch;
  width: 100% !important;
  margin-top: 24px;
}

.searchbar_searchbar__2UR8v {
  display: flex;
  margin: 0;
  height: 48px;
  padding: 0px 16px;
  padding: var(--space-padding-none, 0px) var(--space-padding-md, 16px);
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  grid-gap: var(--space-gap-sm, 12px);
  gap: var(--space-gap-sm, 12px);
  align-self: stretch;
  border-radius: 480px;
  border-radius: var(--space-radius-circular, 480px);
  border: 1px solid
    rgba(34, 34, 34, 0.08);
  border: var(--space-stroke-sm, 1px) solid
    var(--color-stroke-neutral-standard-secondary, rgba(34, 34, 34, 0.08));
  background: #f7f9fa;
  background: var(--color-fill-neutral-secondary-default, #f7f9fa);
  padding-left: 52px;
  padding-right: 52px;
  width: 100%;
}

.searchbar_searchbar__2UR8v::-webkit-input-placeholder {
  overflow: hidden;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: 14px;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchbar_searchbar__2UR8v::-ms-input-placeholder {
  overflow: hidden;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: 14px;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchbar_searchbar__2UR8v::placeholder {
  overflow: hidden;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: 14px;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchbar_searchbar__2UR8v:focus {
  outline: none;
  border: 1px solid #00a7f0 !important;
}

/* container do componente */
.login_container__2Yi5f {
  min-height: 100%;
  padding: 40px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  gap: 32px;
  max-width: 500px !important;
  position: relative;
}

/* Box da logo */
.login_logo__Ap7OD {
  margin: auto;
}

.login_logoBox__Uj5_j {
  padding-top: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  grid-gap: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.login_logoBoxTexts__2NO92 {
  text-align: center;
  width: 100%;
  height: 100%;
  grid-gap: 8px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.login_logoTitle__2FKaZ {
  margin: 0;
  color: #222;
  text-align: center;
  font-size: 40px;
  font-size: var(--type-size-xxl, 40px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login_logoTitleIn__3o_5D {
  margin: 0;
  color: #00a7f0;
  text-align: center;
  font-size: 40px;
  font-size: var(--type-size-xxl, 40px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;
}

.login_logoParagraph__pN_Zn {
  color: rgba(34, 34, 34, 0.56);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 240px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin: 0;
}

/* Box dos inputs */

.login_inputsBox__32X5Z {
  text-align: center;
  width: 100%;
  height: 100%;
  grid-gap: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

/* footer styles */

.login_footerBox__1tH88 {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.login_footerLogo__3hkTp {
  margin: auto;
  width: 126px;
  height: 26px;
}

.login_footerParagraph__3eUKH {
  margin: 0;
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  font-family: Poppins;
  font-family: var(--type-family-principal, Poppins);
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login_footerAppVersion__jZYN0 {
  margin: 0;
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard_dashboardBoxTitle__3sIKN {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 20px !important;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.dashboard_dashboardTitle__o47pG {
  overflow: hidden;
  color: #111;
  text-overflow: ellipsis;
  font-size: 18.864px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.dashboard_refreshButton__2fjOk {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.dashboard_refreshButton__2fjOk:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.charts_gridMiniCards__38iIJ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

@media (max-width: 400px) {
  .charts_gridMiniCards__38iIJ {
    grid-template-columns: 1fr;
  }
}

.mainCard_card__2OTV1 {
  border-radius: 8px;
  border-radius: var(--space-radius-sm, 8px);
  border: 1px solid #eee;
  display: flex;
  height: 181px;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
  grid-gap: var(--space-gap-lg, 20px);
  gap: var(--space-gap-lg, 20px);
  flex-shrink: 0;
}

.mainCard_cardTitle__3CTkg {
  overflow: hidden;
  color: #222;
  text-overflow: clip;
  font-size: 18px;
  font-size: var(--type-size-md, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  white-space: normal;
}

@media (max-width: 400px) {
  .mainCard_cardTitle__3CTkg {
    font-size: 14px;
    font-size: var(--type-size-md, 14px);
  }
}

.mainCard_cardContent__3urP1 {
  display: flex;
  flex-direction: row;
  grid-gap: 32px;
  gap: 32px;
}

@media (max-width: 394px) {
  .mainCard_cardContent__3urP1 {
    grid-gap: 16px;
    gap: 16px;
  }
}

.mainCard_doughnutBox__17Q_K {
  height: 90px;
  width: 90px;
}

.mainCard_doughnutBoxData__3YQZd {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.mainCard_doughnutBoxDataFirstLine__2HlBP {
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  gap: 12px;
}

@media (max-width: 310px) {
  .mainCard_doughnutBoxDataFirstLine__2HlBP {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
  }
}

.mainCard_doughnutBoxDataFirstLineValue__3elhS {
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: center;
  font-size: 24px;
  font-size: var(--type-size-lg, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.mainCard_doughnutBoxDataFirstLineTotal__16e8P {
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-primary, rgba(34, 34, 34, 0.56));
  text-align: center;
  font-size: 24px;
  font-size: var(--type-size-lg, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  display: inline;
}

@media (max-width: 400px) {
  .mainCard_doughnutBoxDataFirstLineValue__3elhS {
    font-size: 16px;
  }
  .mainCard_doughnutBoxDataFirstLineTotal__16e8P {
    font-size: 16px;
  }
}

.mainCard_doughnutBoxDataFirstLinePercentage__MR6LH {
  display: flex;
  height: 26px;
  min-width: 26px;
  padding: 4px 12px
    4px 8px;
  padding: var(--space-padding-xxs, 4px) var(--space-padding-sm, 12px)
    var(--space-padding-xxs, 4px) var(--space-padding-xs, 8px);
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  grid-gap: var(--space-gap-xxs, 4px);
  gap: var(--space-gap-xxs, 4px);
  border-radius: 480px;
  border-radius: var(--space-radius-circular, 480px);
  background: #f2f9fc;
  background: var(--color-fill-brand-secondary-default, #f2f9fc);
  color: #00a7f0;
  color: var(--color-label-brand-default, #00a7f0);
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.mainCard_doughnutBoxInfo__10yZu {
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
}

.mainCard_doughnutBoxInfoText__2KNxN {
  color: #888;
  font-size: 14px;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
}

.miniCard_card__2qJnO {
  border-radius: 8px;
  border-radius: var(--space-radius-sm, 8px);
  border: 1px solid #eee;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
  flex-shrink: 0;
}
.miniCard_cardFirstQuantity__3yjWC {
  color: #888;
  margin: 0;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.miniCard_cardFirstTitle__RuaxG {
  overflow: hidden;
  color: #222;
  margin: 0;
  text-overflow: ellipsis;
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.miniCard_doughnutBox__ITjDk {
  height: 62px;
  width: 62px;
}

.miniCard_doughnutBoxData__3ulCJ {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.miniCard_doughnutBoxDataFirstLine__3LPDP {
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
  gap: 12px;
}

.miniCard_doughnutBoxDataFirstLineValue__2_N-u {
  color: #222;
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: center;
  font-size: 24px;
  font-size: var(--type-size-lg, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.miniCard_doughnutBoxDataFirstLineTotal__35uOC {
  color: rgba(34, 34, 34, 0.56);
  color: var(--color-label-neutral-standard-primary, rgba(34, 34, 34, 0.56));
  text-align: center;
  font-size: 24px;
  font-size: var(--type-size-lg, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  display: inline;
}

.miniCard_doughnutBoxDataFirstLinePercentage__3wvI9 {
  display: flex;
  height: 26px;
  min-width: 26px;
  padding: 4px 12px
    4px 8px;
  padding: var(--space-padding-xxs, 4px) var(--space-padding-sm, 12px)
    var(--space-padding-xxs, 4px) var(--space-padding-xs, 8px);
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  grid-gap: var(--space-gap-xxs, 4px);
  gap: var(--space-gap-xxs, 4px);
  border-radius: 480px;
  border-radius: var(--space-radius-circular, 480px);
  background: #f2f9fc;
  background: var(--color-fill-brand-secondary-default, #f2f9fc);
  color: #00a7f0;
  color: var(--color-label-brand-default, #00a7f0);
  font-size: 12px;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.miniCard_doughnutBoxInfo__2uJfg {
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
}

.miniCard_doughnutBoxInfoText__3bsNH {
  color: #888;
  font-size: 10px;
  font-size: var(--type-size-xs, 10px);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
}

.errorNotFound_container__3fVkA {
  text-align: center;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.errorNotFound_error404__11Ghs {
  font-weight: bold;
  font-size: 30px;
  margin: 0;
}

.errorNotFound_paragraph__yiV74 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #2222227b;
}

.errorNotFound_button__2gO_S {
  margin-top: 12px;
  width: 250px;
}

