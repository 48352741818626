.label {
  overflow: hidden;
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 4px;
}

.input {
  width: 100%;
  display: flex;
  padding: var(--space-padding-sm, 12px) var(--space-padding-xxs, 4px)
    var(--space-padding-sm, 12px) var(--space-padding-sm, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--space-radius-sm, 8px);
  border: var(--space-stroke-sm, 1px) solid
    var(--color-stroke-neutral-standard-secondary, rgba(34, 34, 34, 0.08));
  background: var(--color-background-neutral-primary, #fff);
  display: flex;
  height: 48px;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1 0 0;
}

.input::placeholder {
  overflow: hidden;
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input:focus {
  outline: none;
  border: 1px solid #00a7f0 !important;
}

.inputWarning {
  border: 1px solid #d6780c;
}

.warning {
  margin: 0;
  margin-top: 4px;
  overflow: hidden;
  color: #d6780c;
  text-overflow: ellipsis;
  font-size: var(--type-size-xxxs, 10px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
