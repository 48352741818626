.eventBoxTitle {
  display: flex;
  width: 100%;
  padding: 14px 19px !important;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.eventTitle {
  overflow: hidden;
  color: #111;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
