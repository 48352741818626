/* texto de ajuda */
.helpText {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 336px;
  margin: auto;
  margin-top: 24px;
}

/* CheckinStatus */
.container {
  margin-top: 10px;
  padding: 14px;
  border: 1px solid #eee;
  border-radius: 8px;
  width: 90% !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 12px;
}

.header h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.header h2 {
  color: #888;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 100%;
  margin: 0;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.paragraph {
  overflow: hidden;
  color: #222;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.smallParagraph {
  color: #222;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.documentParagraph,
.ticketNumberParagraph {
  color: #888;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
