.gridMiniCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

@media (max-width: 400px) {
  .gridMiniCards {
    grid-template-columns: 1fr;
  }
}
