.button {
  display: flex;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  padding: var(--space-padding-sm, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--space-gap-sm, 12px);
  align-self: stretch;
  border-radius: var(--space-radius-sm, 8px);
  background: var(--color-fill-brand-primary-default, #00a7f0);
  transition: background-color 0.3s;
  color: var(--color-label-neutral-bgblackish-primary, #fafafa);
  font-family: var(--type-family-principal, Poppins);
  font-size: var(--type-size-sm, 16px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button:hover {
  background-color: #1a7de6;
}
.button:active {
  background-color: #4b98ea;
}

.button:focus {
  background-color: #3791f0;
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
