.dashboardBoxTitle {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 20px !important;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.dashboardTitle {
  overflow: hidden;
  color: #111;
  text-overflow: ellipsis;
  font-size: 18.864px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}

.refreshButton {
  transition: transform 0.3s ease;
}

.refreshButton:hover {
  cursor: pointer;
  transform: scale(1.1);
}
