.scannerBox {
  position: relative;
  width: 100%;
  min-height: 260px;
  max-height: 340px;
  padding: 0 !important;
}

.scannerTarget {
  position: absolute;
  width: 140px;
  height: 140px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.qrReader {
  width: 100%;
  min-height: 260px;
  max-height: 340px;
}
