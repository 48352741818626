.navbarBox {
  position: relative;
  height: 52px;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08) !important;
  background-color: #fafafa;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarLogo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 126px;
  height: 26px;
  z-index: 2;
}
