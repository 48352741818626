body {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f4f4f4;
  line-height: 1.6;
}

a {
  color: #007bff;
  text-decoration: none;
}
