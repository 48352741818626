.menuBox {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 26px;
  z-index: 999;
}

/* Botão de abrir menu */
.menuIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
