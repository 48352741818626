.title {
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: start;
  font-size: var(--type-size-sm, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0px;
  margin-bottom: 24px;
}

.searchBar {
  --background: #f7f9fa;
  --background-hover: #9ce0be;
  --background-activated: #2070fc;
  --background-focused: hsl(218, 97%, 56%);
  --color: #222;
  --box-shadow: 0;
  --border-radius: 480px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  border-radius: 480px;
  border: 1px solid rgba(34, 34, 34, 0.08);
  padding: 0 !important;
  margin-bottom: 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchButton {
  display: flex;
  height: 48px;
  padding: var(--space-padding-sm, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--space-gap-sm, 12px);
  align-self: stretch;
  width: 100% !important;
  margin-top: 24px;
}
