/* container do componente */
.container {
  min-height: 100%;
  padding: 40px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 500px !important;
  position: relative;
}

/* Box da logo */
.logo {
  margin: auto;
}

.logoBox {
  padding-top: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.logoBoxTexts {
  text-align: center;
  width: 100%;
  height: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.logoTitle {
  margin: 0;
  color: #222;
  text-align: center;
  font-size: var(--type-size-xxl, 40px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.logoTitleIn {
  margin: 0;
  color: #00a7f0;
  text-align: center;
  font-size: var(--type-size-xxl, 40px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline;
}

.logoParagraph {
  color: rgba(34, 34, 34, 0.56);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 240px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin: 0;
}

/* Box dos inputs */

.inputsBox {
  text-align: center;
  width: 100%;
  height: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

/* footer styles */

.footerBox {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.footerLogo {
  margin: auto;
  width: 126px;
  height: 26px;
}

.footerParagraph {
  margin: 0;
  color: var(--color-label-neutral-standard-primary, #222);
  font-family: var(--type-family-principal, Poppins);
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footerAppVersion {
  margin: 0;
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
