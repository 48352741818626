.container {
  padding: 16px 20px;
}

.loadingBox {
  margin-top: 28px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
