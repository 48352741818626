.card {
  border-radius: var(--space-radius-sm, 8px);
  border: 1px solid #eee;
  display: flex;
  height: 181px;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-gap-lg, 20px);
  flex-shrink: 0;
}

.cardTitle {
  overflow: hidden;
  color: #222;
  text-overflow: clip;
  font-size: var(--type-size-md, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  white-space: normal;
}

@media (max-width: 400px) {
  .cardTitle {
    font-size: var(--type-size-md, 14px);
  }
}

.cardContent {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

@media (max-width: 394px) {
  .cardContent {
    gap: 16px;
  }
}

.doughnutBox {
  height: 90px;
  width: 90px;
}

.doughnutBoxData {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.doughnutBoxDataFirstLine {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

@media (max-width: 310px) {
  .doughnutBoxDataFirstLine {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.doughnutBoxDataFirstLineValue {
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: center;
  font-size: var(--type-size-lg, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.doughnutBoxDataFirstLineTotal {
  color: var(--color-label-neutral-standard-primary, rgba(34, 34, 34, 0.56));
  text-align: center;
  font-size: var(--type-size-lg, 24px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  display: inline;
}

@media (max-width: 400px) {
  .doughnutBoxDataFirstLineValue {
    font-size: 16px;
  }
  .doughnutBoxDataFirstLineTotal {
    font-size: 16px;
  }
}

.doughnutBoxDataFirstLinePercentage {
  display: flex;
  height: 26px;
  min-width: 26px;
  padding: var(--space-padding-xxs, 4px) var(--space-padding-sm, 12px)
    var(--space-padding-xxs, 4px) var(--space-padding-xs, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--space-gap-xxs, 4px);
  border-radius: var(--space-radius-circular, 480px);
  background: var(--color-fill-brand-secondary-default, #f2f9fc);
  color: var(--color-label-brand-default, #00a7f0);
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.doughnutBoxInfo {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.doughnutBoxInfoText {
  color: #888;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0;
}
