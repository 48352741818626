/* ------------MenuIconButton */
/* box do menu */
.menuBox {
  position: absolute;
  left: 33px;
  top: 53%;
  transform: translate(-50%, -50%);
  height: 26px;
  z-index: 999;
}

/* Botão de abrir menu */
.menuIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

/* ------------MenuBody */
.menuMainBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000;
  transform: translateX(-100%);
  visibility: hidden;
  transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
  height: 100vh;
}

.menuMainBox.open {
  visibility: visible;
  transform: translateX(0%);
}

.menuIconBackBox {
  height: 52px;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  background-color: #fafafa;
  cursor: pointer;
}
.menuIconBack {
  cursor: pointer;
}

.menuBodyBox {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 85%;
  background-color: #ffffff;
}

.menuBodyTitle {
  margin: 0;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  color: var(--color-label-neutral-standard-primary, #222);
  font-size: var(--type-size-sm, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 16px;
}

.menuBodyButton {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--space-padding-md, 16px) var(--space-padding-xxs, 4px);
  gap: var(--space-padding-sm, 12px);
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  transition: border-color 0.3s ease;
}

.menuBodyButton:hover {
  display: flex;
  align-items: center;
  padding: var(--space-padding-md, 16px) var(--space-padding-xxs, 4px);
  gap: var(--space-padding-sm, 12px);
  border-bottom: 1px solid #00a7f0;
  cursor: pointer;
}

.menuBodyLabels {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.menuBodyButtonLabel {
  color: var(--color-label-neutral-standard-primary, #222);
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.menuBodyButtonSubLabel {
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.menuBodyArrowForward {
  margin-left: auto;
  margin-right: 10px;
}

.menuLogoutButton {
  margin-top: auto;
  border-bottom: 1px solid rgba(34, 34, 34, 0.08);
  gap: 12px;
  display: flex;
  flex-direction: row;
  padding: 16px 4px;
  transition: border-color 0.3s ease;
}
.menuLogoutButton:hover {
  border-bottom: 1px solid #00a7f0;
  gap: 12px;
  display: flex;
  flex-direction: row;
  padding: 16px 4px;
  cursor: pointer;
}
.menuLogoutText {
  margin: 0;
  color: var(--color-label-neutral-standard-primary, #222);
  /* Label medium */
  font-family: var(--type-family-principal, Poppins);
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.menuVersionBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.menuVersionBoxText {
  margin: 0;
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
