/* Typography.css */
.typography {
  margin: 0;
  font-family: Arial, sans-serif;
}

.typography.h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.typography.h2 {
  font-size: 2rem;
  font-weight: bold;
}

.typography.h3 {
  font-size: 1.75rem;
  font-weight: bold;
}

.typography.h4 {
  font-size: 1.5rem;
  font-weight: bold;
}

.typography.h5 {
  font-size: 1.25rem;
  font-weight: bold;
}

.typography.h6 {
  font-size: 1rem;
  font-weight: bold;
}

.typography.p {
  font-size: 1rem;
}

.typography.span {
  font-size: 1rem;
}

.typography.small {
  font-size: 0.875rem;
}
