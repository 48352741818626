/* texto de ajuda */
.helpText {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 336px;
  margin: auto;
  margin-top: 24px;
}

/* AttendeeCard */
.container {
  padding: 14px 0px;
  margin: 0px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  gap: 20px !important;
  cursor: pointer;
  transition: border-color 0.3s ease;
  box-shadow: none;
  justify-content: space-between;
}

.container:hover {
  border-color: #eeeeee;
  background-color: #fbfafa;
}

.container:active {
  border-color: #c9c8f0;
  background-color: #efeff2;
}

.header {
  align-items: center;
  display: flex;
  gap: 12px;
}

.header h1 {
  color: blue;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.leftBodyCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  width: 80%;
}

.rightBodyCard {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.doCheckin {
  display: flex;
  height: 28px;
  padding: var(--space-padding-xxs, 4px);
  justify-content: center;
  align-items: center;
  width: 110px;
  gap: var(--space-gap-xxs, 4px);
  border-radius: var(--space-radius-xs, 4px);
  border: var(--space-stroke-sm, 1px) solid
    var(--color-stroke-brand-primary, #00b2ff);
  color: var(--color-label-brand-default, #00b2ff);
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: background-color 0.3s, color 0.3s;
}

.doCheckin:hover {
  background-color: var(--color-stroke-brand-primary, #00b2ff);
  color: #fff;
}

.paragraph {
  overflow: hidden;
  color: #222;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.smallParagraph {
  color: #222;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.documentParagraph,
.ticketNumberParagraph {
  color: #888;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.boxNotFound {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  align-items: center;
  width: 100%;
}

.iconNotFound {
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: center;
  font-size: var(--type-size-md, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.textNotFound {
  color: var(--color-label-neutral-standard-primary, #222);
  text-align: center;
  font-size: var(--type-size-md, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
