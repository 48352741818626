.searchbar {
  display: flex;
  margin: 0;
  height: 48px;
  padding: var(--space-padding-none, 0px) var(--space-padding-md, 16px);
  align-items: center;
  gap: var(--space-gap-sm, 12px);
  align-self: stretch;
  border-radius: var(--space-radius-circular, 480px);
  border: var(--space-stroke-sm, 1px) solid
    var(--color-stroke-neutral-standard-secondary, rgba(34, 34, 34, 0.08));
  background: var(--color-fill-neutral-secondary-default, #f7f9fa);
  padding-left: 52px;
  padding-right: 52px;
  width: 100%;
}

.searchbar::placeholder {
  overflow: hidden;
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  text-overflow: ellipsis;
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchbar:focus {
  outline: none;
  border: 1px solid #00a7f0 !important;
}
