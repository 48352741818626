.alertMainBox {
  display: flex;
  flex-direction: column;
  height: 0;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.alertMainBox.alertMainOpen {
  opacity: 1;
  transform: translateX(0%);
  height: initial;
}

.alertBox {
  height: 45px;
  padding: 12px 20px;
  color: #ffffff;
  text-align: start;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  transform: translateX(-100%);
  opacity: 0; /* Use opacity instead of visibility */
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.alertBox.alertOpen {
  opacity: 1; /* Use opacity instead of visibility */
  transform: translateX(0%);
}
.alertMessage {
  color: var(--color-label-neutral-bgblackish-primary, #fafafa);
  font-size: var(--type-size-xs, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  line-height: 1.02;
  width: 90%;
}

.subMessage {
  color: var(--color-label-neutral-standard-secondary, rgba(34, 34, 34, 0.56));
  font-size: var(--type-size-xxs, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.xIcon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
