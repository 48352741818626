.tag {
  display: inline-flex;
  align-self: flex-start; /* This prevents it from filling the cross-axis in a flex parent */
  padding: var(--space-padding-xxs, 4px) var(--space-padding-sm, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--space-gap-xs, 8px);
  border-radius: var(--space-radius-xs, 4px);
  background: var(--color-fill-feedback-success-primary-default, #53a462);
  color: var(--color-label-neutral-bgblackish-primary, #fafafa);
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
